
  /* import './Editor.css' */

  .public-DraftStyleDefault-block {
    margin: 0px !important;
  }
  
  .editor-height{
    min-height: 300px;
    overflow: auto;
}